<template>
  <div
    class="flex flex-col py-24 text-gray-600 dark:text-gray-200 justify-center items-center space-x-2 space-y-2"
  >
    <font-awesome-icon class="text-9xl" :icon="['fas', 'frown']" />
    <h1 class="pt-5 text-2xl font-semibold text-center">
      Oops, We couldn't find the page.
    </h1>
    <h3>Try visiting our available pages</h3>
    <div
      class="font-semibold flex space-x-3 text-blue-600 dark:text-blue-300 underline"
    >
      <router-link
        class="block focus:outline-none opacity-80 hover:opacity-100"
        to="/"
        >Home</router-link
      >
      <router-link
        class="block focus:outline-none opacity-80 hover:opacity-100"
        to="/services"
        >Services</router-link
      >
    </div>
  </div>
</template>
